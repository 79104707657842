import React from "react"
import Layout from "../../components/layout"
// import PeakSuiteButton from "../../components/peaksuiteProduct"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PeaksuiteTrailheadSection = loadable(() =>
  pMinDelay(
    import("../../components/peaksuite-sections/PeaksuiteTrailheadSection"),
    500
  )
)

const Banner = () => (
  <div>
    <div className="hero-banner peaksuite-wrap-hero">
      <div className="container">
        <div
          className="hero-banner-content text-center"
          style={{ padding: "50px 0" }}
        >
          <div
            className="hero-title-wrap text-center"
            style={{ background: "#fff" }}
          >
            <ScrollAnimation animateIn="fadeInLeft" initiallyVisible={true}>
              <Link aria-label="link" to="/peaksuite/peaksuite-trailhead/">
                <StaticImage
                  placeholder="blurred"
                  imgClassName="img-fluid"
                  src="../../../static/assets/productLogos/PS_trailhead.png"
                  alt="peakSUITE TRAILHEAD"
                  width={400}
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  </div>
)
class ProductPeakSuite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "peakSuite | Trailhead",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    localStorage.setItem("title", this.state.title)
  }
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="peakSUITE Trailhead | Custom Add-Ons for Quickbase"
          description="Trailhead tracks various project deliverables and due dates, guiding each one through phases until official completion. Built in Quickbase. Learn more."
        />
        <PeaksuiteTrailheadSection handleSubmit={this.handleSubmit} />
      </Layout>
    )
  }
}

export default ProductPeakSuite

// export const PeakSuiteQuery = graphql`
//   query PeakSuiteQuery {

//   }
// `
